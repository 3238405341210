import { Container } from "components/container";
import classNames from "classnames";
import { Cloudinary } from "components/cloudinary";
import { unescape } from "helpers/text-processing";
import { Card } from "components/card";
import { Slider } from "components/slider";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  CloudinaryItemProps,
  CloudinaryProps,
  ContentBannerEntryProps,
  ContentBannerWrapperProps,
} from "constants/types";
import { TipTapComponent } from "../text";
import _debounce from "lodash/debounce";
import { createClipPathPolygon } from "helpers/clipPath";

const ContentBannerSlide: FC<ContentBannerEntryProps> = (props) => {
  const [clipPath, setClipPath] = useState<string>("");
  const contentRef = useRef<HTMLDivElement>();

  const color = useMemo(() => {
    switch (props.background_color as string) {
      case "white": {
        return "blue";
      }
      default: {
        return "white";
      }
    }
  }, [props.background_color]);

  const hasBackground = useMemo(() => {
    return !!props?.select_image;
  }, [props.select_image]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const imageLeft = useMemo(() => props.image_position === "left", []);

  useEffect(() => {
    const updateClipPath = () => {
      if (imageLeft) {
        setClipPath(
          createClipPathPolygon(contentRef.current, 8, "end", "up-left"),
        );
      } else {
        setClipPath(
          createClipPathPolygon(contentRef.current, 8, "start", "up-left"),
        );
      }
    };

    if (contentRef.current) {
      updateClipPath();

      window.addEventListener("resize", _debounce(updateClipPath, 200));

      return () => {
        window.removeEventListener("resize", _debounce(updateClipPath, 200));
      };
    }
  }, [contentRef, imageLeft]);

  return (
    <Container
      className={classNames("my-4", "section", "contentbanner__container")}
    >
      <div
        className="contentbanner-disruptor"
        style={{
          ...(props.fixed_height && {
            gridTemplateRows: `${props.fixed_height}px`,
            height: `${props.fixed_height}px`,
          }),
        }}
      >
        {props.eye_catcher && (
          <div className="contentbanner-eyecatcher background-gradient-secondary">
            {props.eye_catcher}
          </div>
        )}
        <div
          className={classNames(
            "contentbanner-child",
            "contentbanner-content",
            color,
            `bg_${props.background_color}`,
            imageLeft && "content-right",
            !hasBackground && "no-background",
          )}
          ref={contentRef}
          style={{
            clipPath: clipPath,
            ...(hasBackground && {
              width: `${100 - +props?.image_width + 6}%`,
            }),
            ...(imageLeft
              ? {
                  justifySelf: "flex-end",
                }
              : { justifySelf: "initial" }),
          }}
        >
          {props.entity_or_text === "entity" ? (
            <Card
              key={props?.select_entity?.id}
              title={props?.select_entity?.title}
              featuredLogos={
                "featured_logos" in props.select_entity &&
                props?.select_entity?.featured_logos?.length > 0 &&
                props.select_entity.featured_logos
                  .slice(0, 2)
                  .map((featuredLogo) => ({
                    media: featuredLogo,
                    width: 80,
                    height: 60,
                  }))
              }
              cardImg={
                props?.select_entity?.key_visual?.length > 0 && {
                  media: props.select_entity.key_visual?.[0],
                  ar: "ar43",
                  background: "grey-triangle",
                  width: 430,
                  height: 323,
                }
              }
              variant="vertical"
            >
              <p className="">{unescape(props?.select_entity?.subtitle)}</p>
              <TipTapComponent tree={props?.text_content} />
            </Card>
          ) : (
            <Card
              title={props?.headline}
              cardImg={
                props?.entry_image &&
                ({
                  media: props.entry_image,
                  ar: "ar43",
                  background: "grey-triangle",
                  width: 430,
                  height: 323,
                } as CloudinaryItemProps)
              }
              variant="vertical"
            >
              <TipTapComponent tree={props?.text_content} />
            </Card>
          )}
        </div>
        {props?.select_image && (
          <div
            className={classNames("contentbanner-child", "contentbanner-image")}
            style={{
              [imageLeft ? "paddingRight" : "paddingLeft"]: `${
                100 - +props?.image_width - 5
              }%`,
            }}
          >
            <Cloudinary
              media={props.select_image as unknown as CloudinaryProps}
              ar="ar169"
            />
          </div>
        )}
      </div>
    </Container>
  );
};

const ContentBannerWrapper: FC<ContentBannerWrapperProps> = (props) => {
  return (
    <Slider
      slidesPerView={1}
      autoplay={{ delay: 9993000 }}
      navigation={props.partOfGrid ? "container" : "default"}
      spaceBetween={0}
      className={classNames(
        "contentbanner__slider",
        /* TODO: don't see a reason why it's used here. Commented out for testing */
        /* !props.partOfGrid && "container", */
      )}
    >
      {props.entry_list
        .filter((entry) =>
          entry.enabled && entry.entity_or_text === "entity"
            ? entry?.select_entity
            : true,
        )
        .map((entry, index) => {
          return (
            <ContentBannerSlide
              key={index}
              {...entry}
              {...(props.fix_slider_height &&
                props.fixed_height && { fixed_height: props.fixed_height })}
            />
          );
        })}
    </Slider>
  );
};

export default ContentBannerWrapper;
